var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',[_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","fields":_vm.fields,"items":_vm.items,"busy":_vm.isBusy,"show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('router-link',{attrs:{"to":'/sendmessage/detail/' + data.item.id}},[_c('u',[_vm._v(" "+_vm._s(data.item.name))])]),(data.item.message_id == 0)?_c('font-awesome-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              title:
                'Processing your request, please wait a few moment. If this icon still show after some time pass, please contact admin.',
              placement: 'left',
              variant: 'warning',
            }),expression:"{\n              title:\n                'Processing your request, please wait a few moment. If this icon still show after some time pass, please contact admin.',\n              placement: 'left',\n              variant: 'warning',\n            }"}],staticClass:"ml-2 f-16",attrs:{"icon":"exclamation-circle"}}):_vm._e()]}},{key:"cell(send_time)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm._f("moment")(data.item.send_time,_vm.$formatDateNew)))]),_c('div',{staticClass:"time-color"},[_vm._v(" "+_vm._s(_vm._f("moment")(data.item.send_time,"HH:mm:ss"))+" ")])]}},{key:"cell(status_id)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[(data.item.status_id === 1)?_c('span',{staticClass:"text-danger"},[_vm._v("Cancel")]):_vm._e(),(data.item.status_id === 2)?_c('span',{staticClass:"text-success"},[_vm._v("Sent")]):_vm._e(),(data.item.status_id === 3)?_c('span',{staticClass:"status-3"},[_vm._v("Send immediately")]):_vm._e(),(data.item.status_id === 4)?_c('span',{staticClass:"status-4"},[_vm._v("Schedule a post")]):_vm._e(),(data.item.status_id === 5)?_c('span',{staticClass:"status-5"},[_vm._v("Draft")]):_vm._e()])]}},{key:"cell(goal_percen)",fn:function(data){return [(data.item.is_goal == 0 || data.item.message_id == 0)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(" "+_vm._s(data.item.numGoal)+" ("+_vm._s(data.item.goal_percen)+")")])]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"text-warning px-1 py-0",attrs:{"variant":"link"},on:{"click":function($event){$event.preventDefault();return _vm.editMessage(data.item.id)}}},[_c('font-awesome-icon',{attrs:{"icon":"pencil-alt","title":"Edit"}})],1)],1)]}},{key:"cell(list)",fn:function(data){return [_c('div',{staticClass:"d-flex"},[(
                data.item.status_id === 2 ||
                data.item.status_id === 3 ||
                data.item.status_id === 4
              )?_c('b-dropdown',{staticClass:"automation",attrs:{"size":"md","variant":"link","toggle-class":"text-decoration-none text-gray p-0","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{staticClass:"mt-2 pointer",attrs:{"icon":"ellipsis-v"}})]},proxy:true}],null,true)},[(data.item.status_id === 2 || data.item.status_id === 3)?_c('b-dropdown-item-button',{on:{"click":function($event){$event.preventDefault();return _vm.resendMessage(true, data.item.id)}}},[_vm._v("Repeat")]):_vm._e(),(data.item.status_id === 4)?_c('b-dropdown-item-button',{attrs:{"disabled":data.item.message_id == 0},on:{"click":function($event){$event.preventDefault();return _vm.resendMessage(false, data.item.id)}}},[_vm._v("Cancel")]):_vm._e()],1):_vm._e()],1)]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-black my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"ml-2"},[_vm._v("Loading...")])],1)]},proxy:true}])})],1)],1),_c('Pagination',{attrs:{"pageOptions":_vm.pageOptions,"filter":_vm.filter,"rows":_vm.rows},on:{"handleChangeTake":_vm.hanndleChangePerpage,"pagination":_vm.pagination}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }